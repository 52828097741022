import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import { Paper, IconButton, Grid, Tooltip } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useConfirmDeleteDialog from '../../hooks/useConfirmDeleteDialog';
import useCheckPermission from '../../hooks/useCheckPermission';
import DataList from '../../components/DataList';
import rssSettingsService from '../../services/rssSettingsService';
export interface RssSettings {
  id: string;
  imageId: string;
  name: string;
}

const RssSettingssPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    rssSettingsService
      .list()
      .then((response) => {
        if (!response.hasError) {
          setRows(response.records);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    let readonly = !checkPermission(['UpdateRssSettings']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="View">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Edit">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'View' : 'Edit'}
        onClick={() => navigate(`/rssSettings/edit/${params.row.id}`)}
      />
    );

    if (checkPermission(['DeleteRssSettings'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Delete">
              <DeleteIcon />
            </Tooltip>
          }
          label="Delete"
          onClick={() =>
            setParams({
              open: true,
              name: 'Are you sure to delete this item?',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    { field: 'publisherName', headerName: 'Publikáló', flex: 100 },
    { field: 'tagContent', headerName: 'Címke', flex: 100 },
    { field: 'feedUrl', headerName: 'Url', flex: 100 },
    {
      field: 'sendPushNotification',
      headerName: 'Értesítés küldése',
      flex: 100,
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
    },
    {
      field: 'isPublished',
      headerName: 'Publikált',
      flex: 100,
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    rssSettingsService.delete(id).then((response) => {
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés!', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2> RSS beállítások</h2>
        </Grid>
        {checkPermission(['CreateRssSettings']) && (
          <Grid container item xs={12} p={2} justifyContent="end">
            <Grid item>
              <Tooltip title="Rss beállítás létrehozása">
                <IconButton
                  component={RouterLink}
                  to={`/rssSettings/create`}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataList rows={rows} columns={columns} />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
    </Paper>
  );
};

export default RssSettingssPage;
