import CircularSlider from '@fseehawer/react-circular-slider';
import { Refresh, ResetTv, Restore } from '@mui/icons-material';
import {
  Modal,
  Paper,
  Grid,
  Slider,
  Button,
  Switch,
  FormControlLabel,
  Typography,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

export const rotateSize = (width: number, height: number, rotation: number) => {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
};

const CropImageModal: React.FC<any> = ({
  open,
  onClose,
  imageToUpload,
  crop,
  setCrop,
  getImageData,
}) => {
  const [temporaryCrop, setTemporaryCrop] = useState<any>(crop);

  useEffect(() => {
    if (!crop) return;
    setTemporaryCrop(crop);
  }, [crop]);

  return (
    <Modal open={open} onClose={onClose}>
      <Paper>
        <Grid container>
          {imageToUpload && (
            <>
              <Grid item xs={12}>
                <Cropper
                  image={URL.createObjectURL(imageToUpload)}
                  crop={temporaryCrop.crop}
                  zoom={temporaryCrop.zoom}
                  aspect={temporaryCrop.aspect}
                  rotation={temporaryCrop.rotation}
                  cropShape={temporaryCrop.shape}
                  onCropChange={(v) => {
                    if (
                      temporaryCrop.crop.x == v.x &&
                      temporaryCrop.crop.y == v.y
                    )
                      return;

                    setTemporaryCrop((actual: any) => {
                      return {
                        ...actual,
                        crop: v,
                      };
                    });
                  }}
                  onZoomChange={(v) => {
                    if (temporaryCrop.zoom == v) return;

                    setTemporaryCrop((actual: any) => {
                      return {
                        ...actual,
                        zoom: v,
                      };
                    });
                  }}
                  onCropAreaChange={(v) => {}}
                  onCropComplete={(c, cropedArea) => {
                    if (
                      temporaryCrop.area.x == cropedArea.x &&
                      temporaryCrop.area.y == cropedArea.y &&
                      temporaryCrop.area.width == cropedArea.width &&
                      temporaryCrop.area.height == cropedArea.height &&
                      temporaryCrop.rotation == temporaryCrop.rotation &&
                      temporaryCrop.shape == temporaryCrop.shape &&
                      temporaryCrop.zoom == temporaryCrop.zoom
                    )
                      return;

                    setTemporaryCrop((actual: any) => {
                      return {
                        ...actual,
                        area: cropedArea,
                      };
                    });
                  }}
                />
              </Grid>
              <Grid
                item
                justifyContent="center"
                container
                xs={12}
                style={{
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                  zIndex: 999999999,
                }}
              >
                <Grid
                  item
                  xs={12}
                  pt={3}
                  container
                  justifyContent="space-between"
                >
                  <Grid
                    item
                    xs={4}
                    container
                    pl={5}
                    style={{
                      backgroundImage:
                        'radial-gradient(ellipse at 0 100%, rgba(255,255,255,0.8) 0, rgba(255,255,255,0.8) 70%, transparent 50%, transparent 20%)',
                    }}
                  >
                    <Grid item xs={12} pt={5}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={temporaryCrop.shape === 'round'}
                            onChange={() => {
                              setTemporaryCrop((actual: any) => {
                                return {
                                  ...actual,
                                  shape:
                                    actual.shape === 'rect' ? 'round' : 'rect',
                                };
                              });
                            }}
                            inputProps={{ 'aria-label': 'crop shape switch' }}
                          />
                        }
                        label="Kerekített"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption">
                        Nagyítás {(temporaryCrop.zoom * 100).toFixed(0)}%
                      </Typography>
                      <Slider
                        value={temporaryCrop.zoom}
                        min={1}
                        max={5}
                        step={0.1}
                        onChange={(_: any, value: any) =>
                          setTemporaryCrop((actual: any) => {
                            return { ...actual, zoom: value as number };
                          })
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={4} container alignItems="end">
                    <Grid
                      item
                      height={75}
                      container
                      xs={12}
                      pb={3}
                      mx={5}
                      spacing={2}
                      justifyContent="center"
                      style={{
                        backgroundColor: 'rgba(255,255,255,0.8)',
                        borderRadius: '100px 100px 0 0',
                      }}
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          onClick={() => {
                            getImageData(
                              temporaryCrop.area,
                              temporaryCrop.shape,
                              temporaryCrop.rotation,
                              imageToUpload
                            ).then((data: any) => {
                              setCrop((actual: any) => {
                                return {
                                  ...actual,
                                  image: data,
                                };
                              });
                              onClose();
                            });
                          }}
                        >
                          Mentés{' '}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="outlined" onClick={onClose}>
                          Bezár
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    container
                    justifyContent="flex-end"
                    style={{
                      backgroundImage:
                        'radial-gradient(ellipse at 100% 100%, rgba(255,255,255,0.8) 0, rgba(255,255,255,0.8) 70%, transparent 50%, transparent 20%)',
                    }}
                  >
                    <Grid
                      sx={{
                        transform: {
                          xs: 'scale(0.5)',
                          md: 'scale(0.6)',
                          lg: 'scale(0.75)',
                          xl: 'scale(0.9)',
                        },
                      }}
                      item
                      mr={-4}
                      pr={0}
                      pt={5}
                    >
                      {
                        <CircularSlider
                          label="Forgatás szöge"
                          labelColor="#1976d2"
                          knobColor="#1976d2"
                          progressColorFrom="#3086DC"
                          progressColorTo="#337FCB"
                          progressSize={20}
                          trackColor="#404145"
                          trackSize={20}
                          min={0}
                          max={360}
                          dataIndex={temporaryCrop.rotation}
                          onChange={(value: number) => {
                            setTemporaryCrop((actual: any) => {
                              return { ...actual, rotation: value as number };
                            });
                          }}
                        />
                      }
                    </Grid>
                    <Grid item ml={-10}>
                      <Button
                        variant="contained"
                        sx={{ display: { xs: 'none', md: 'block' } }}
                        size="small"
                        onClick={() => {
                          setTemporaryCrop((actual: any) => {
                            return {
                              ...actual,
                              rotation: 0,
                            };
                          });
                        }}
                      >
                        Alaphelyzet
                      </Button>
                      <IconButton
                        sx={{ display: { xs: 'block', md: 'none' } }}
                        onClick={() => {
                          setTemporaryCrop((actual: any) => {
                            return {
                              ...actual,
                              rotation: 0,
                            };
                          });
                        }}
                      >
                        <Refresh color="primary" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </Modal>
  );
};

export default CropImageModal;
