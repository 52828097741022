import { Route, Routes } from 'react-router-dom';
import { Dashboard } from './Dashboard';
import RoleCreate from '../pages/authority/roles/RoleCreate';
import RoleEdit from '../pages/authority/roles/RoleEdit';
import RolePage from '../pages/authority/roles/RolePage';
import UserGroupCreate from '../pages/authority/userGroups/UserGroupCreate';
import UserGroupEdit from '../pages/authority/userGroups/UserGroupEdit';
import UserGroupPage from '../pages/authority/userGroups/UserGroupPage';
import UserPage from '../pages/authority/users/roles/UserPage';
import UserEdit from '../pages/authority/users/roles/UserEdit';
import SystemConfigurationsPage from '../pages/systemConfigurations/SystemConfigurationPage';
import SystemConfigurationEdit from '../pages/systemConfigurations/SystemConfigurationEdit';
import TagCreate from '../pages/tags/TagCreate';
import TagEdit from '../pages/tags/TagEdit';
import TagsPage from '../pages/tags/TagPage';
import PublisherPage from '../pages/publishers/PublisherPage';
import PublisherCreate from '../pages/publishers/PublisherCreate';
import PublisherEdit from '../pages/publishers/PublisherEdit';
import FaqCategoryEdit from '../pages/faqCategories/FaqCategoryEdit';
import FaqCategoryPage from '../pages/faqCategories/FaqCategoryPage';
import FaqCategoryCreate from '../pages/faqCategories/FaqCategoryCreate';
import FaqsPage from '../pages/faqs/FaqPage';
import FaqCreate from '../pages/faqs/FaqCreate';
import FaqEdit from '../pages/faqs/FaqEdit';
import OfficialsPage from '../pages/officials/OfficialPage';
import OfficialCreate from '../pages/officials/OfficialCreate';
import OfficialEdit from '../pages/officials/OfficialEdit';
import ReportsPage from '../pages/reports/ReportPage';
import ReportView from '../pages/reports/ReportView';
import ContentPage from '../pages/contents/ContentPage';
import ContentCreate from '../pages/contents/ContentCreate';
import ContentEdit from '../pages/contents/ContentEdit';
import PagePage from '../pages/pages/PagePage';
import PageCreate from '../pages/pages/PageCreate';
import PageEdit from '../pages/pages/PageEdit';
import LocationCreate from '../pages/locations/LocationCreate';
import LocationEdit from '../pages/locations/LocationEdit';
import LocationPage from '../pages/locations/LocationPage';
import PublisherTypePage from '../pages/publisherTypes/PublisherTypePage';
import PublisherTypeCreate from '../pages/publisherTypes/PublisherTypeCreate';
import PublisherTypeEdit from '../pages/publisherTypes/PublisherTypeEdit';
import MenuItemPage from '../pages/menuItems/MenuItemPage';
import ReportCategoryCreate from '../pages/reportCategories/ReportCategoryCreate';
import ReportCategoryEdit from '../pages/reportCategories/ReportCategoryEdit';
import ReportCategoryPage from '../pages/reportCategories/ReportCategoryPage';
import MapObjectCategoryCreate from '../pages/mapObjectCategories/MapObjectCategoryCreate';
import MapObjectCategoryEdit from '../pages/mapObjectCategories/MapObjectCategoryEdit';
import MapObjectCategoryPage from '../pages/mapObjectCategories/MapObjectCategoryPage';
import MapObjectCreate from '../pages/mapObjects/MapObjectCreate';
import MapObjectEdit from '../pages/mapObjects/MapObjectEdit';
import MapObjectPage from '../pages/mapObjects/MapObjectPage';
import GalleryPage from '../pages/gallery/galleryPage';
import RssSettingssPage from '../pages/rssSettings/RssSettingssPage';
import RssSettingsCreate from '../pages/rssSettings/RssSettingsCreate';
import RssSettingsEdit from '../pages/rssSettings/RssSettingsEdit';
import MobileApplicationThemesPage from '../pages/mobileApplicationThemes/MobileApplicationThemesPage';
import MobileApplicationThemeCreate from '../pages/mobileApplicationThemes/MobileApplicationThemeCreate';
import MobileApplicationThemeEdit from '../pages/mobileApplicationThemes/MobileApplicationThemeEdit';
import EmailTemplateCreate from '../pages/emailTemplates/EmailTemplateCreate';
import EmailTemplateEdit from '../pages/emailTemplates/EmailTemplateEdit';
import EmailTemplateHomePage from '../pages/emailTemplates/EmailTemplateHomePage';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/authority/userGroups" element={<UserGroupPage />} />
      <Route
        path="/authority/userGroups/create"
        element={<UserGroupCreate />}
      />
      <Route
        path="/authority/userGroups/edit/:id"
        element={<UserGroupEdit />}
      />
      <Route path="/authority/roles" element={<RolePage />} />
      <Route path="/authority/roles/create" element={<RoleCreate />} />
      <Route path="/authority/roles/edit/:id" element={<RoleEdit />} />
      <Route path="/authority/users" element={<UserPage />} />
      <Route path="/authority/users/edit/:id" element={<UserEdit />} />

      <Route path="/gallery" element={<GalleryPage />} />

      <Route path="/tags" element={<TagsPage />} />
      <Route path="/tags/create" element={<TagCreate />} />
      <Route path="/tags/edit/:id" element={<TagEdit />} />

      <Route path="/rssSettings" element={<RssSettingssPage />} />
      <Route path="/rssSettings/create" element={<RssSettingsCreate />} />
      <Route path="/rssSettings/edit/:id" element={<RssSettingsEdit />} />

      <Route
        path="/mobileApplicationTheme"
        element={<MobileApplicationThemesPage />}
      />
      <Route
        path="/mobileApplicationTheme/create"
        element={<MobileApplicationThemeCreate />}
      />
      <Route
        path="/mobileApplicationTheme/edit/:id"
        element={<MobileApplicationThemeEdit />}
      />
      <Route path="/erp/emailTemplates" element={<EmailTemplateHomePage />} />
      <Route
        path="/erp/emailTemplates/create"
        element={<EmailTemplateCreate />}
      />
      <Route
        path="/erp/emailTemplates/edit/:id"
        element={<EmailTemplateEdit />}
      />
      <Route path="/publishertypes" element={<PublisherTypePage />} />
      <Route path="/publishertypes/create" element={<PublisherTypeCreate />} />
      <Route path="/publishertypes/edit/:id" element={<PublisherTypeEdit />} />

      <Route path="/publishers" element={<PublisherPage />} />
      <Route path="/publishers/create" element={<PublisherCreate />} />
      <Route path="/publishers/edit/:id" element={<PublisherEdit />} />

      <Route path="/mapobjects" element={<MapObjectPage />} />
      <Route path="/mapobjects/create" element={<MapObjectCreate />} />
      <Route path="/mapobjects/edit/:id" element={<MapObjectEdit />} />

      <Route path="/mapObjectCategories" element={<MapObjectCategoryPage />} />
      <Route
        path="/mapObjectCategories/create"
        element={<MapObjectCategoryCreate />}
      />
      <Route
        path="/mapObjectCategories/edit/:id"
        element={<MapObjectCategoryEdit />}
      />

      <Route path="/faqCategories" element={<FaqCategoryPage />} />
      <Route path="/faqCategories/create" element={<FaqCategoryCreate />} />
      <Route path="/faqCategories/edit/:id" element={<FaqCategoryEdit />} />

      <Route path="/reportCategories" element={<ReportCategoryPage />} />
      <Route
        path="/reportCategories/create"
        element={<ReportCategoryCreate />}
      />
      <Route
        path="/reportCategories/edit/:id"
        element={<ReportCategoryEdit />}
      />

      <Route path="/faqs" element={<FaqsPage />} />
      <Route path="/faqs/create" element={<FaqCreate />} />
      <Route path="/faqs/edit/:id" element={<FaqEdit />} />

      <Route path="/officials" element={<OfficialsPage />} />
      <Route path="/officials/create" element={<OfficialCreate />} />
      <Route path="/officials/edit/:id" element={<OfficialEdit />} />

      <Route path="/menuDesigner" element={<MenuItemPage />} />

      <Route path="/contents" element={<ContentPage />} />
      <Route path="/contents/create" element={<ContentCreate />} />
      <Route path="/contents/edit/:id" element={<ContentEdit />} />

      <Route path="/pages" element={<PagePage />} />
      <Route path="/pages/create" element={<PageCreate />} />
      <Route path="/pages/edit/:id" element={<PageEdit />} />

      <Route path="/locations" element={<LocationPage />} />
      <Route path="/locations/create" element={<LocationCreate />} />
      <Route path="/locations/edit/:id" element={<LocationEdit />} />

      <Route path="/reports" element={<ReportsPage />} />
      <Route path="/reports/view/:id" element={<ReportView />} />

      <Route
        path="/systemConfigurations"
        element={<SystemConfigurationsPage />}
      />
      <Route
        path="/systemConfigurations/edit/:id"
        element={<SystemConfigurationEdit />}
      />
      <Route path="/" element={<Dashboard />} />
    </Routes>
  );
};

export default AppRoutes;
