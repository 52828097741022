import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
  Stack,
  Switch,
  Typography,
  Chip,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Form, Formik } from 'formik';
import useImageSelect from '../../hooks/useImageSelect';
import { useEffect, useState } from 'react';
import IconPicker from '../../components/IconPicker';
import IconPickerToggle from '../../components/IconPickerToggle';
import CreateEntityDecorator from '../../components/CreateEntityDecorator';
import TagCreate from '../tags/TagCreate';
import useCheckPermission from '../../hooks/useCheckPermission';

const RssSettingsForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    publishers,
    readonly = false,
    tags,
    onTagAdded,
  } = props;

  const { checkPermission } = useCheckPermission();

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                RSS beállítás {values.id > 0 ? 'szerkesztése' : 'létrehozása'}{' '}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error: string) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  disablePortal
                  id="publisherId"
                  disabled={readonly}
                  value={values.publisherId}
                  onChange={(event, value) => {
                    setFieldValue('publisherId', value);
                  }}
                  getOptionLabel={(option) =>
                    publishers?.find((g: any) => g.id === option)?.name ?? ''
                  }
                  options={publishers?.map((g: any) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      label="Publikáló"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.feedUrl}
                  onChange={handleChange}
                  label="Url"
                  name="feedUrl"
                  required
                  disabled={readonly}
                  fullWidth
                  error={!!touched.feedUrl && !!validationErrors.feedUrl}
                  helperText={
                    !!touched.feedUrl &&
                    !!validationErrors.feedUrl &&
                    (validationErrors.feedUrl as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CreateEntityDecorator
                  hideAdd={!checkPermission(['CreateTag'])}
                  AutocompleteComponent={
                    <Autocomplete
                      disablePortal
                      id="tagId"
                      disabled={readonly}
                      value={values.tagId}
                      onChange={(event, value) => {
                        setFieldValue('tagId', value);
                      }}
                      getOptionLabel={(option) =>
                        tags
                          .filter(
                            (x: any) => x.publisherId == values.publisherId
                          )
                          .find((g: any) => g.id === option)?.content ?? ''
                      }
                      options={tags
                        .filter((x: any) => x.publisherId == values.publisherId)
                        .map((g: any) => g.id)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Címke"
                          fullWidth
                          required
                          error={!!touched.tagId && !!validationErrors.tagId}
                          helperText={
                            !!touched.tagId &&
                            !!validationErrors.tagId &&
                            (validationErrors.tagId as string)
                          }
                        />
                      )}
                    />
                  }
                  onSave={(tag: any) => {
                    if (tag && tag.publisherId == values.publisherId)
                      setFieldValue('tagId', tag.id);
                    onTagAdded(tag);
                  }}
                  CreateEntityComponent={TagCreate}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label="Push értesítés küldése"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      value={values.sendPushNotification}
                      checked={values.sendPushNotification}
                      onChange={(e) => {
                        setFieldValue('sendPushNotification', e.target.checked);
                      }}
                    ></Checkbox>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label="Publikált cikkek létrehozása"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      value={values.isPublished}
                      checked={values.isPublished}
                      onChange={(e) => {
                        setFieldValue('isPublished', e.target.checked);
                      }}
                    ></Checkbox>
                  }
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default RssSettingsForm;
