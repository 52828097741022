import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  useMediaQuery,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Chip,
  Divider,
  Switch,
  Typography,
  Stack,
  RadioGroup,
  Radio,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import CreateEntityDecorator from '../../components/CreateEntityDecorator';
import useCheckPermission from '../../hooks/useCheckPermission';
import TagCreate from '../tags/TagCreate';
import 'suneditor/dist/css/suneditor.min.css';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import LocationCreate from '../locations/LocationCreate';
import SunEditor from 'suneditor-react';
import SunEditorCore from 'suneditor/src/lib/core';
import ReactDiffViewer from 'react-diff-viewer-continued';
import { InputDiff } from '../../components/InputDiff';
import moment from 'moment';
import { getHtmlContent } from '../../utils/htmContentLoader';
import useImageSelect from '../../hooks/useImageSelect';
import { object, string, array, number, date, InferType } from 'yup';
import 'katex/dist/katex.min.css';
import { set } from 'date-fns';

const ContentForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    tags,
    publishers,
    onTagAdded,
    locations,
    onLocationAdded,
    versions = [],
  } = props;

  const { checkPermission } = useCheckPermission();

  const [showDifferences, setShowDifferences] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(0);
  const [previousVersion, setPreviousVersion] = useState<any>();
  const editor = useRef<SunEditorCore>();
  const [selectedOption, setSelectedOption] = useState('normal');

  const validationSchema = object().shape({
    shortTitle: string().required('A rövid cím megadása kötelező'),
    shortDescription: string().required('A rövid leírás megadása kötelező'),
    publisherId: number().required('A publikáló megadása kötelező'),
    imageId: number().required('A kép megadása kötelező'),
    tagIds: array().min(1, 'Legalább egy címke megadása kötelező'),
  });

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  const { ImageSelectComponent, uploadImage, uploading, error, uploadedFile } =
    useImageSelect();

  const handleUploaded = (image: any, setFieldValue: any) => {
    setFieldValue('imageId', image.id);
    setFieldValue('image', image);
  };

  useEffect(() => {
    setSelectedVersion(entity.version);
    setPreviousVersion(versions.find((x: any) => x.version == entity.version));
  }, [entity]);

  const highlightSyntax = (str: string) => (
    <div
      dangerouslySetInnerHTML={{
        __html: str,
      }}
    />
  );

  useEffect(() => {
    if (entity && entity.sourceUrl.length > 0 && !(entity.rssSettingsId > 0)) {
      setSelectedOption('external');
    }
    if (entity && entity.rssSettingsId > 0) {
      setSelectedOption('rss');
    }
  }, [entity]);

  useEffect(() => {
    if (previousVersion)
      getHtmlContent(previousVersion.htmlContent).then((content) => {
        setPreviousVersion({
          ...previousVersion,
          htmlContent: content,
        });
      });
  }, [previousVersion?.version]);

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        let newValues = { ...values };
        if (values.publisherId) {
          uploadImage(
            (image: any) =>
              (newValues = { ...values, image: image, imageId: image.id }),
            values.shortTitle,
            values.publisherId,
            '-1'
          )?.then(() => {
            onSubmit(newValues, setSubmitting);
          });
        }
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => {
        return (
          <Form>
            <Paper>
              <Grid container justifyContent="left" spacing={2} p={5}>
                <Grid container justifyContent="space-between" item xs={12}>
                  <Grid item>
                    <h2>
                      Tartalom {values.id > 0 ? 'szerkesztés' : 'létrehozás'}
                    </h2>
                  </Grid>
                  {previousVersion && (
                    <Grid item container justifyContent="end" xs={8}>
                      <Grid item xs={2}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={showDifferences}
                              onChange={() =>
                                setShowDifferences(!showDifferences)
                              }
                            />
                          }
                          label="Változtatások mutatása"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Autocomplete
                          options={versions.map((x: any) => x.version)}
                          getOptionLabel={(option) => {
                            var version = versions.find(
                              (x: any) => x.version == option
                            );
                            return version?.status == 5
                              ? 'v' + option.toString() + ' (Jóváhagyott)'
                              : 'v' + option.toString();
                          }}
                          value={selectedVersion}
                          onChange={(event, newValue: any) => {
                            setSelectedVersion(newValue);
                            setPreviousVersion(
                              versions.find((x: any) => x.version == newValue)
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Összehasonlítás az alábbi verzióval:"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid xs={12} item pt={5}>
                  <Box color="red">
                    {errors.map((error: string) => (
                      <li>{error}</li>
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    aria-label="menu-option"
                    name="menu-option"
                    value={selectedOption}
                    onChange={(event: any) => {
                      setFieldValue('sourceUrl', '');
                      setFieldValue('htmlContent', '');
                      setFieldValue('title', '');
                      setFieldValue('description', '');
                      setSelectedOption(event.target.value);
                    }}
                    row
                  >
                    <FormControlLabel
                      value="normal"
                       control={<Radio disabled={readonly || entity.rssSettingsId > 0} />}
                      label="Normál cikk"
                    />
                    <FormControlLabel
                      value="external"
                      control={<Radio disabled={readonly || entity.rssSettingsId > 0} />}
                      label="Külsős cikk"
                    />
                    <FormControlLabel
                      value="rss"
                      control={<Radio disabled={true} />}
                      label="RSS cikk"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    id="publisherId"
                    disabled={readonly}
                    value={values.publisherId}
                    onChange={(event, value) => {
                      setFieldValue('publisherId', value);
                    }}
                    getOptionLabel={(option) =>
                      publishers?.find((g: any) => g.id === option)?.name ?? ''
                    }
                    options={publishers?.map((g: any) => g.id)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        error={
                          !!touched.publisherId &&
                          !!validationErrors.publisherId
                        }
                        helperText={
                          !!touched.publisherId &&
                          !!validationErrors.publisherId &&
                          (validationErrors.publisherId as string)
                        }
                        label="Publikáló"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MobileDateTimePicker
                    label="Publikálás dátuma"
                    sx={{ width: '100%' }}
                    value={values.publicationDateUtc}
                    disabled={readonly}
                    onChange={(value) => {
                      setFieldValue('publicationDateUtc', value);
                    }}
                    ampm={false}
                  />
                </Grid>
                <Grid item xs={12} textAlign={'center'}>
                  <Grid item xs={12} pb={2}>
                    <TextField
                      label={'Címlap kép'}
                      value={
                        selectedOption !== 'rss'
                          ? values.image?.fileName ?? ''
                          : values.rssImageUrl
                      }
                      fullWidth
                      required
                      disabled
                      error={!!touched.imageId && !!validationErrors.imageId}
                      helperText={
                        !!touched.imageId &&
                        !!validationErrors.imageId &&
                        (validationErrors.imageId as string)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ImageSelectComponent
                      image={values.image}
                      onSelect={(image: any) => {
                        setFieldValue('imageId', image.id);
                        setFieldValue('image', image);
                      }}
                      alt="kép"
                      onUploaded={(image: any) =>
                        handleUploaded(image, setFieldValue)
                      }
                      publisherId={values.publisherId}
                      currentFolderId={'-1'}
                    />
                  </Grid>
                </Grid>
                {selectedOption === 'normal' && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        value={values.title}
                        onChange={handleChange}
                        label="Cím"
                        name="title"
                        required={selectedOption === 'normal'}
                        disabled={readonly}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={!!touched.title && !!validationErrors.title}
                        helperText={
                          !!touched.title &&
                          !!validationErrors.title &&
                          (validationErrors.title as string)
                        }
                      />
                      {showDifferences && previousVersion && (
                        <InputDiff
                          oldValue={previousVersion.title}
                          newValue={values.title}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={values.description}
                        onChange={handleChange}
                        label="Leírás"
                        name="description"
                        multiline
                        rows={4}
                        required={selectedOption === 'normal'}
                        disabled={readonly}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={
                          !!touched.description &&
                          !!validationErrors.description
                        }
                        helperText={
                          !!touched.description &&
                          !!validationErrors.description &&
                          (validationErrors.description as string)
                        }
                      />
                      {showDifferences && previousVersion && (
                        <InputDiff
                          oldValue={previousVersion.description}
                          newValue={values.description}
                        />
                      )}
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <TextField
                    value={values.shortTitle}
                    onChange={handleChange}
                    label="Rövid cím"
                    name="shortTitle"
                    required
                    disabled={readonly}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={
                      !!touched.shortTitle && !!validationErrors.shortTitle
                    }
                    helperText={
                      !!touched.shortTitle &&
                      !!validationErrors.shortTitle &&
                      (validationErrors.shortTitle as string)
                    }
                  />{' '}
                  {showDifferences && previousVersion && (
                    <InputDiff
                      oldValue={previousVersion.shortTitle}
                      newValue={values.shortTitle}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={values.shortDescription}
                    onChange={handleChange}
                    label="Rövid leírás"
                    name="shortDescription"
                    multiline
                    rows={4}
                    required
                    disabled={readonly}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={
                      !!touched.shortDescription &&
                      !!validationErrors.shortDescription
                    }
                    helperText={
                      !!touched.shortDescription &&
                      !!validationErrors.shortDescription &&
                      (validationErrors.shortDescription as string)
                    }
                  />
                  {showDifferences && previousVersion && (
                    <InputDiff
                      oldValue={previousVersion.shortDescription}
                      newValue={values.shortDescription}
                    />
                  )}
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      label="Esemény"
                      labelPlacement="start"
                      disabled={readonly}
                      control={
                        <Checkbox
                          value={values.hasEvent}
                          checked={values.hasEvent}
                          onChange={(e) => {
                            setFieldValue('hasEvent', e.target.checked);
                          }}
                        ></Checkbox>
                      }
                    />
                  </Grid>
                  {values.hasEvent && (
                    <Grid container item xs={12} spacing={2} p={2}>
                      <Grid item xs={12}>
                        <Divider textAlign="left" sx={{ mt: 2 }}></Divider>
                      </Grid>
                      <Grid item xs={12} md={'auto'}>
                        <MobileDateTimePicker
                          label="Esemény kezdete"
                          value={values.eventStartUtc}
                          disabled={readonly}
                          onChange={(value) => {
                            setFieldValue('eventStartUtc', value);
                          }}
                          ampm={false}
                        />
                      </Grid>
                      <Grid item xs={12} md={'auto'}>
                        <MobileDateTimePicker
                          label="Esemény vége"
                          value={values.eventEndUtc}
                          disabled={readonly}
                          onChange={(value) => {
                            setFieldValue('eventEndUtc', value);
                          }}
                          ampm={false}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          value={values.eventName}
                          onChange={handleChange}
                          label="Esemény neve"
                          name="eventName"
                          required={values.hasEvent}
                          disabled={readonly}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={
                            !!touched.eventName && !!validationErrors.eventName
                          }
                          helperText={
                            !!touched.eventName &&
                            !!validationErrors.eventName &&
                            (validationErrors.eventName as string)
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          value={values.eventDescription}
                          onChange={handleChange}
                          label="Esemény leírása"
                          name="eventDescription"
                          required={values.hasEvent}
                          disabled={readonly}
                          fullWidth
                          multiline
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={
                            !!touched.eventDescription &&
                            !!validationErrors.eventDescription
                          }
                          helperText={
                            !!touched.eventDescription &&
                            !!validationErrors.eventDescription &&
                            (validationErrors.eventDescription as string)
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CreateEntityDecorator
                          hideAdd={!checkPermission(['CreateLocation'])}
                          AutocompleteComponent={
                            <Autocomplete
                              disablePortal
                              id="eventLocationId"
                              disabled={readonly}
                              value={values.eventLocationId}
                              onChange={(event, value) => {
                                setFieldValue('eventLocationId', value);
                              }}
                              getOptionLabel={(option) =>
                                locations.find((g: any) => g.id === option)
                                  ?.name ?? ''
                              }
                              options={locations.map((g: any) => g.id)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Helyszín"
                                  fullWidth
                                  error={
                                    !!touched.eventLocationId &&
                                    !!validationErrors.eventLocationId
                                  }
                                  helperText={
                                    !!touched.eventLocationId &&
                                    !!validationErrors.eventLocationId &&
                                    (validationErrors.eventLocationId as string)
                                  }
                                />
                              )}
                            />
                          }
                          onSave={(location: any) => {
                            setFieldValue('eventLocationId', location.id);
                            onLocationAdded(location);
                          }}
                          CreateEntityComponent={LocationCreate}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider textAlign="left" sx={{ mt: 2 }}></Divider>
                      </Grid>{' '}
                    </Grid>
                  )}
                </Grid>
                {selectedOption !== 'normal' && (
                  <Grid item xs={12}>
                    <TextField
                      value={values.sourceUrl}
                      onChange={handleChange}
                      label="Forrás"
                      name="sourceUrl"
                        disabled={readonly || entity.rssSettingsId > 0}
                      fullWidth
                      required={selectedOption !== 'normal'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={
                        !!touched.sourceUrl && !!validationErrors.sourceUrl
                      }
                      helperText={
                        !!touched.sourceUrl &&
                        !!validationErrors.sourceUrl &&
                        (validationErrors.sourceUrl as string)
                      }
                    />
                  </Grid>
                )}
                {checkPermission(['SendPushnotification']) && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      label="Push értesítés küldése"
                        disabled={readonly || entity.rssSettingsId > 0}
                      labelPlacement="start"
                      control={
                        <Checkbox
                          value={values.sendPushNotification}
                          checked={values.sendPushNotification}
                          onChange={(e) => {
                            setFieldValue(
                              'sendPushNotification',
                              e.target.checked
                            );
                          }}
                        ></Checkbox>
                      }
                    />
                  </Grid>
                )}
                {selectedOption === 'normal' && (
                  <Grid item xs={12}>
                    <SunEditor
                      getSunEditorInstance={getSunEditorInstance}
                      width="100%"
                      height="500px"
                      setContents={values.htmlContent}
                      placeholder="Please type here..."
                      disable={readonly || entity.rssSettingsId > 0}
                      defaultValue={values.htmlContent}
                      onChange={(content: string) => {
                        setFieldValue('htmlContent', content);
                      }}
                      onDrop={(event: any, core: any, cleanData: any) => {
                        console.log('onDrop:', event, core, cleanData);
                      }}
                      setOptions={{
                        buttonList: [
                          ['undo', 'redo'],
                          ['font', 'fontSize', 'formatBlock'],
                          [
                            'bold',
                            'underline',
                            'italic',
                            'strike',
                            'subscript',
                            'superscript',
                          ],
                          ['fontColor', 'hiliteColor', 'textStyle'],
                          ['removeFormat'],
                          '/', // line break
                          ['outdent', 'indent'],
                          ['align', 'horizontalRule', 'list', 'table'],
                          ['link', 'image', 'video'],
                          ['fullScreen', 'showBlocks', 'codeView'],
                          ['preview', 'print'],
                          //['save', 'template'],
                          ['math'],
                          //['imageGallery'],
                        ],
                        katex: 'window.katex',
                        templates: [],
                      }}
                    />
                    {/*   {versions.length > 0 &&
                  showDifferences &&
                  previousVersion?.htmlContent && (
                    <ReactDiffViewer
                      oldValue={
                        previousVersion?.htmlContent
                          ? previousVersion.htmlContent.replace(
                              /(data:image\/[^;]+;base64[^"]+)/g,
                              ''
                            )
                          : 'No previous version found'
                      }
                      newValue={values.htmlContent.replace(
                        /(data:image\/[^;]+;base64[^"]+)/g,
                        ''
                      )}
                      splitView={true}
                      renderContent={highlightSyntax}
                    />
                  )} */}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <CreateEntityDecorator
                                hideAdd={!checkPermission(['CreateTag']) || readonly || entity.rssSettingsId > 0}
                    AutocompleteComponent={
                      <Autocomplete
                        disablePortal
                        multiple
                        id="tagIds"
                        disabled={readonly || entity.rssSettingsId > 0}
                        value={values.tagIds}
                        onChange={(event, value) => {
                          var tag = tags.find((x: any) => x.id == value);
                          if (tag && tag.publisherId != values.publisherId)
                            return;

                          setFieldValue('tagIds', value);
                          if (values.tagIds.length === 0) {
                            setFieldValue('primaryTagId', value[0]);
                          }
                        }}
                        getOptionLabel={(option) =>
                          tags
                            .filter(
                              (x: any) => x.publisherId == values.publisherId
                            )
                            .find((g: any) => g.id === option)?.content ?? ''
                        }
                        options={tags
                          .filter(
                            (x: any) => x.publisherId == values.publisherId
                          )
                          .map((g: any) => g.id)}
                        renderTags={(value: any, getTagProps: any) =>
                          value.map((option: any, index: any) => (
                            <Chip
                              key={option}
                              variant={
                                option == values.primaryTagId
                                  ? 'filled'
                                  : 'outlined'
                              }
                              color="primary"
                              onClick={() => {
                                setFieldValue('primaryTagId', option);
                              }}
                              label={
                                tags.find((g: any) => g.id === option)?.content
                              }
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Címkék"
                            fullWidth
                            error={
                              !!touched.tagIds && !!validationErrors.tagIds
                            }
                            helperText={
                              !!touched.tagIds &&
                              !!validationErrors.tagIds &&
                              (validationErrors.tagIds as string)
                            }
                          />
                        )}
                      />
                    }
                    onSave={(tag: any) => {
                      if (tag && tag.publisherId == values.publisherId)
                        setFieldValue('tagIds', [...values.tagIds, tag.id]);
                      onTagAdded(tag);
                    }}
                    CreateEntityComponent={TagCreate}
                  />
                </Grid>
                <Grid container item pt={3} justifyContent="left">
                  {!readonly &&
                      <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          fullWidth={isSmallScreen}
                          disabled={
                            isSubmitting ||
                            values.tagIds.length === 0 ||
                            values.publisherId === 0
                          }
                        >
                          Mentés
                        </Button>
                      </Grid>
                    }
                  {!readonly && (
                    <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          onSubmit(values, isSubmitting, true);
                        }}
                        fullWidth={isSmallScreen}
                        disabled={
                          isSubmitting ||
                          values.tagIds.length === 0 ||
                          values.publisherId === 0
                        }
                      >
                        Mentés piszkozatként
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth={isSmallScreen}
                      onClick={handleCancel}
                    >
                      Mégse
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ContentForm;
