import { FileArray } from '@aperturerobotics/chonky';
import { useMemo } from 'react';
import { CustomFileMap } from './useGalleryFileMap';

export const useFiles = (
  fileMap: CustomFileMap,
  currentFolderId: string
): FileArray => {
  const getShortFileName = (fileName: string) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    const type =
      lastDotIndex === -1
        ? ''
        : fileName.substring(lastDotIndex, fileName.length);
    const baseFileName =
      lastDotIndex === -1 ? fileName : fileName.substring(0, lastDotIndex);
    const shortFileName = baseFileName.substring(0, 8) + '...' + type;
    return shortFileName;
  };

  return useMemo(() => {
    const currentFolder = fileMap[currentFolderId ?? '-1'];

    const childrenIds = currentFolder?.childrenIds ?? [];
    const files = childrenIds.map((fileId: string) => fileMap[fileId]);
    console.log('files', files);
    const shortFiles = files.map((file) => {
      return {
        ...file,
        name: getShortFileName(file.name),
      };
    });
    return shortFiles;
  }, [currentFolderId, fileMap]);
};
