import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { translateRoleName } from '../roles/RoleForm';

export const translateGroupName = (name: string) => {
  switch (name) {
    case 'Administrators':
      return 'Adminisztrátorok';
    case 'Operators':
      return 'Kezelők';
    case 'Assistants':
      return 'Bolti munkatársak';
    case 'ServiceEmployees':
      return 'Szerviz munkatársak';
    default:
      return name;
  }
};

const UserGroupForm = (props: any) => {
  const { entity, onSubmit, errors, handleCancel, users, roles } = props;

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Kötelező mező!';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        handleChange,
        setFieldValue,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Felhasználói csoport{' '}
                {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error: string) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={translateGroupName(values.name)}
                  onChange={handleChange}
                  label="Megnevezés"
                  disabled={values.isSystem}
                  name="name"
                  fullWidth
                  autoFocus
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="users"
                  multiple
                  value={values.users}
                  onChange={(event, value) => {
                    setFieldValue('users', value);
                  }}
                  getOptionLabel={(option) => {
                    let user = users.find((g: { id: any }) => g.id === option);
                    return `${user?.fullName} (${user?.email ?? ''})`;
                  }}
                  options={users
                    .map((g: any) => g.id)
                    .filter(
                      (x: any) => !values.users.some((y: any) => y === x)
                    )}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Felhasználó" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="roles"
                  multiple
                  value={values.roles}
                  onChange={(event, value) => {
                    setFieldValue('roles', value);
                  }}
                  getOptionLabel={(option) =>
                    translateRoleName(
                      roles.find((g: { id: any }) => g.id === option)?.name ??
                        ''
                    )
                  }
                  options={roles
                    .map((g: { id: any }) => g.id)
                    .filter(
                      (x: any) => !values.roles.some((y: any) => y === x)
                    )}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Szerepkör" />
                  )}
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                <Grid item p={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Mentés
                  </Button>
                </Grid>
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default UserGroupForm;
