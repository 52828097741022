import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import { Box, Paper, IconButton, Grid, Tooltip } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import DataList from '../../../components/DataList';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import userGroupService from '../../../services/authority/userGroupService';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { translateGroupName } from './UserGroupForm';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';

export interface UserGroup {
  id: string;
  userGroupGroupId: string;
}

const UserGroupsPage = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    userGroupService
      .list()
      .then((response) => {
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Megnevezés',
      valueGetter: (param) => translateGroupName(param.row.name),
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 20,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Szerkesztés">
              <EditIcon color="primary" />
            </Tooltip>
          }
          label="Edit"
          onClick={() =>
            navigate(`/authority/userGroups/edit/${params.row.id}`)
          }
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon color="primary" />
            </Tooltip>
          }
          label="Delete"
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />,
      ],
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    userGroupService.delete(id).then((response) => {
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Felhasználói csoportok</h2>
        </Grid>
        <Grid container item xs={12} p={2} justifyContent="end">
          <Grid item>
            <Tooltip title="Csoport létrehozása">
              <IconButton
                component={RouterLink}
                to={`/authority/userGroups/create`}
                color="primary"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DataList rows={rows} columns={columns} />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
    </Paper>
  );
};

export default UserGroupsPage;
