import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
  Stack,
  Switch,
  Typography,
  Chip,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Form, Formik } from 'formik';
import useImageSelect from '../../hooks/useImageSelect';
import { useEffect, useState } from 'react';
import IconPicker from '../../components/IconPicker';
import IconPickerToggle from '../../components/IconPickerToggle';
import CreateEntityDecorator from '../../components/CreateEntityDecorator';
import TagCreate from '../tags/TagCreate';
import useCheckPermission from '../../hooks/useCheckPermission';
import { SketchPicker } from 'react-color';
import { DatePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';

const MobileApplicationThemeForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    publishers,
    readonly = false,
    existingThemes,
  } = props;

  const { checkPermission } = useCheckPermission();
  const [primaryColorPicker, setPrimaryColorPicker] = useState(false);
  const [secondaryColorPicker, setSecondaryColorPicker] = useState(false);
  const [iconPrimaryColorPicker, setIconPrimaryColorPicker] = useState(false);
  const [iconSecondaryColorPicker, setIconSecondaryColorPicker] =
    useState(false);
  const [illustrationPrimaryColorPicker, setIllustrationPrimaryColorPicker] =
    useState(false);

  const { ImageSelectComponent } = useImageSelect();
  const second = useImageSelect();
  const { enqueueSnackbar } = useSnackbar();

  const [publisherId, setPublisherId] = useState<number | null>(0);

  useEffect(() => {
    setPublisherId(entity.logo?.publisherId ?? 0);
  }, [entity]);

  const isDateInvalid = (date: any, id: any) => {
    let dateFormat = new Date(date);
    return existingThemes.some((x: any) => {
      if (x.id === id) return false;
      let startDate = new Date(x.startDate);
      let endDate = new Date(x.endDate);
      let result = dateFormat >= startDate && dateFormat <= endDate;

      return result;
    });
  };

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Applikáció téma {values.id > 0 ? 'szerkesztése' : 'létrehozása'}{' '}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error: string) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  disablePortal
                  id="publisherId"
                  disabled={readonly}
                  value={publisherId}
                  onChange={(event, value) => {
                    setPublisherId(value);
                  }}
                  getOptionLabel={(option) =>
                    publishers?.find((g: any) => g.id === option)?.name ?? ''
                  }
                  options={publishers?.map((g: any) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      label="Galéria választás"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">Logó:</Typography>
              </Grid>
              <Grid item xs={12}>
                <ImageSelectComponent
                  image={values.logo}
                  onSelect={(image: any) => {
                    setFieldValue('logoId', image.id);
                    setFieldValue('logo', image);
                  }}
                  alt="kép"
                  onUploaded={(image: any) => {
                    setFieldValue('logoId', image.id);
                    setFieldValue('logo', image);
                  }}
                  publisherId={publisherId}
                  currentFolderId={'-1'}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">Háttérkép:</Typography>
              </Grid>
              <Grid item xs={12}>
                <second.ImageSelectComponent
                  image={values.backgroundImage}
                  onSelect={(image: any) => {
                    setFieldValue('backgroundImageId', image.id);
                    setFieldValue('backgroundImage', image);
                  }}
                  alt="kép"
                  onUploaded={(image: any) => {
                    setFieldValue('backgroundImageId', image.id);
                    setFieldValue('backgroundImage', image);
                  }}
                  publisherId={publisherId}
                  currentFolderId={'-1'}
                />
              </Grid>
              <Grid container item xs={12} alignItems="center" pb={2}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    onClick={() => setPrimaryColorPicker(!primaryColorPicker)}
                    style={{
                      width: '30px',
                      height: '30px',
                      backgroundColor: values.primaryColor,
                      cursor: 'pointer',
                      borderRadius: '50%',
                    }}
                  ></div>
                  <h3 style={{ marginRight: '10px' }}>Elsődleges szín</h3>
                </div>
                {primaryColorPicker && (
                  <SketchPicker
                    color={values.primaryColor}
                    onChangeComplete={(newColor) =>
                      setFieldValue('primaryColor', newColor.hex)
                    }
                  />
                )}
              </Grid>
              <Grid container item xs={12} alignItems="center" pb={2}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    onClick={() =>
                      setSecondaryColorPicker(!secondaryColorPicker)
                    }
                    style={{
                      width: '30px',
                      height: '30px',
                      backgroundColor: values.secondaryColor,
                      cursor: 'pointer',
                      borderRadius: '50%',
                    }}
                  ></div>
                  <h3 style={{ marginRight: '10px' }}>Másodlagos szín</h3>
                </div>
                {secondaryColorPicker && (
                  <SketchPicker
                    color={values.secondaryColor}
                    onChangeComplete={(newColor) =>
                      setFieldValue('secondaryColor', newColor.hex)
                    }
                  />
                )}
              </Grid>
              <Grid container item xs={12} alignItems="center" pb={2}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    onClick={() =>
                      setIconPrimaryColorPicker(!iconPrimaryColorPicker)
                    }
                    style={{
                      width: '30px',
                      height: '30px',
                      backgroundColor: values.iconPrimaryColor,
                      cursor: 'pointer',
                      borderRadius: '50%',
                    }}
                  ></div>
                  <h3 style={{ marginRight: '10px' }}>Elsődleges ikon szín</h3>
                </div>
                {iconPrimaryColorPicker && (
                  <SketchPicker
                    color={values.iconPrimaryColor}
                    onChangeComplete={(newColor) =>
                      setFieldValue('iconPrimaryColor', newColor.hex)
                    }
                  />
                )}
              </Grid>
              <Grid container item xs={12} alignItems="center" pb={2}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    onClick={() =>
                      setIconSecondaryColorPicker(!iconSecondaryColorPicker)
                    }
                    style={{
                      width: '30px',
                      height: '30px',
                      backgroundColor: values.iconSecondaryColor,
                      cursor: 'pointer',
                      borderRadius: '50%',
                    }}
                  ></div>
                  <h3 style={{ marginRight: '10px' }}>Másodlagos ikon szín</h3>
                </div>
                {iconSecondaryColorPicker && (
                  <SketchPicker
                    color={values.iconSecondaryColor}
                    onChangeComplete={(newColor) =>
                      setFieldValue('iconSecondaryColor', newColor.hex)
                    }
                  />
                )}
              </Grid>
              <Grid container item xs={12} alignItems="center" pb={2}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    onClick={() =>
                      setIllustrationPrimaryColorPicker(
                        !illustrationPrimaryColorPicker
                      )
                    }
                    style={{
                      width: '30px',
                      height: '30px',
                      backgroundColor: values.illustrationPrimaryColor,
                      cursor: 'pointer',
                      borderRadius: '50%',
                    }}
                  ></div>
                  <h3 style={{ marginRight: '10px' }}>
                    Elsődleges illusztráció szín
                  </h3>
                </div>
                {illustrationPrimaryColorPicker && (
                  <SketchPicker
                    color={values.illustrationPrimaryColor}
                    onChangeComplete={(newColor) =>
                      setFieldValue('illustrationPrimaryColor', newColor.hex)
                    }
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  label="Kezdő dátum"
                  sx={{ width: '100%' }}
                  value={values?.startDate}
                  disabled={readonly}
                  shouldDisableDate={(date) => {
                    return isDateInvalid(date, values.id);
                  }}
                  onChange={(value) => {
                    if (!isDateInvalid(value, values.id)) {
                      setFieldValue('startDate', value);
                    } else {
                      enqueueSnackbar(
                        'Erre az időszakra már létezik egy téma! Kérem válasszon másik időszakot!',
                        { variant: 'error' }
                      );
                    }
                  }}
                  format="YYYY-MM-DD"
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  label="Vég dátum"
                  sx={{ width: '100%' }}
                  value={values?.endDate}
                  disabled={readonly}
                  shouldDisableDate={(date) => {
                    return isDateInvalid(date, values.id);
                  }}
                  onChange={(value) => {
                    if (!isDateInvalid(value, values.id)) {
                      setFieldValue('endDate', value);
                    } else {
                      enqueueSnackbar(
                        'Erre az időszakra már létezik egy téma! Kérem válasszon másik időszakot!',
                        { variant: 'error' }
                      );
                    }
                  }}
                  format="YYYY-MM-DD"
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default MobileApplicationThemeForm;
