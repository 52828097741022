import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
  GridRowParams,
} from '@mui/x-data-grid';
import { Box, Paper, Grid, Tooltip } from '@mui/material';
import { Edit as EditIcon, Visibility } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import DataList from '../../components/DataList';
import useCheckPermission from '../../hooks/useCheckPermission';
import systemConfigurationService from '../../services/systemConfigurationService';

export interface SystemConfiguration {
  id: number;
  key: string;
  value: string;
  description: string;
}

const SystemConfigurationsPage = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    systemConfigurationService
      .list()
      .then((response) => {
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const getActions = (params: GridRowParams) => {
    var actions = [];

    let readonly = !checkPermission(['ManageSystemConfigurations']);
    actions.push(
      <GridActionsCellItem
        icon={
          readonly ? (
            <Tooltip title="View">
              <Visibility color="primary" />
            </Tooltip>
          ) : (
            <Tooltip title="Edit">
              <EditIcon color="primary" />
            </Tooltip>
          )
        }
        label={readonly ? 'View' : 'Edit'}
        onClick={() => navigate(`/systemConfigurations/edit/${params.row.id}`)}
      />
    );

    return actions;
  };

  const columns: GridColDef[] = [
    { field: 'key', headerName: 'Key', flex: 100 },
    {
      field: 'description',
      headerName: 'Description',
      flex: 100,
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 20,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>SystemConfigurations</h2>
        </Grid>
        <Grid item xs={12}>
          <DataList rows={rows} columns={columns} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SystemConfigurationsPage;
