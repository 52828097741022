import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import {
  Paper,
  IconButton,
  Grid,
  Tooltip,
  Box,
  Tab,
  Tabs,
  useMediaQuery,
  Badge,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
  Publish,
  Pin,
  PushPin,
  HideImage,
  Image,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useConfirmDeleteDialog from '../../hooks/useConfirmDeleteDialog';
import useCheckPermission from '../../hooks/useCheckPermission';
import DataList from '../../components/DataList';
import contentService from '../../services/contentService';
import { formatTime } from '../../utils/formatters';
import CheckIcon from '@mui/icons-material/Check';
import ApproveDialog from './ApproveDialog';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DatePickerHeader from '../../components/DatePickerHeader';
import { useLocalStorage } from '../../hooks/useLocalStorage';

export interface Content {
  id: number;
  contentCategoryId: number;
  Question: string;
  Answer: string;
}

export enum ApprovementStatus {
  Draft = 1,
  SentToApproval = 2,
  Rejected = 3,
  ApprovedFirstLevel = 4,
  ApprovedSecondLevel = 5,
  SentToDelete = 6,
  DeletedFirstLevel = 7,
  DeletedSecondLevel = 8,
  DeletedPermanently = 9,
}

const ContentPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [originalRows, setOriginalRows] = useState<any>({
    draftContents: [],
    sendToApprovementContents: [],
    rejectedContents: [],
    approvedContents: [],
    deletedContents: [],
  });
  const [filteredRows, setFilteredRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [filters, setFilters] = useState<any>(ApprovementStatus.Draft);
  const tabLocalStorage = useLocalStorage('ContentPageTabValue');
  const filtersLocalStorage = useLocalStorage('ContentPageFilters');
  const [tabValue, setTabValue] = useState(0);
  const [self, setSelf] = useState<boolean>(false);
  const [showRssContents, setShowRssContents] = useState<boolean>(true);
  const [showExternalContents, setShowExternalContents] =
    useState<boolean>(true);
  const [dateTabValue, setDateTabValue] = useState(5);
  const isSmallScreen = useMediaQuery('(max-width:900px)');
  const [selectedInterval, setSelectedInterval] = useState<any>({
    startDate: null,
    endDate: null,
  });

  const { checkPermission } = useCheckPermission();
  const [openApproveDialog, setOpenApproveDialog] = useState({
    open: false,
    id: '',
    comment: '',
    isSubmitting: false,
    status: ApprovementStatus.Draft,
  });

  useEffect(() => {
    if (tabLocalStorage.value) {
      setTabValue(tabLocalStorage.value);
      switch (tabLocalStorage.value) {
        case 0:
          setFilters(ApprovementStatus.Draft);
          break;
        case 1:
          setFilters(ApprovementStatus.SentToApproval);
          break;
        case 2:
          setFilters(ApprovementStatus.ApprovedSecondLevel);
          break;
        case 3:
          setFilters(ApprovementStatus.Rejected);
          break;
        case 4:
          setFilters(ApprovementStatus.DeletedSecondLevel);
          break;
      }
    }
  }, [tabLocalStorage]);

  useEffect(() => {
    if (filtersLocalStorage.value) {
      setSelf(
        filtersLocalStorage.value.self === undefined
          ? false
          : filtersLocalStorage.value.self
      );
      setShowRssContents(
        filtersLocalStorage.value.showRssContents === undefined
          ? true
          : filtersLocalStorage.value.showRssContents
      );
      setShowExternalContents(
        filtersLocalStorage.value.showExternalContents === undefined
          ? true
          : filtersLocalStorage.value.showExternalContents
      );
    }
  }, [filtersLocalStorage]);

  const handleRecover = (id: any) => {
    dispatch({ type: 'SHOW_QUERY' });
    contentService
      .recover(id)
      .then((response) => {
        if (!response.hasError) {
          enqueueSnackbar('Sikeres visszaállítás!', {
            variant: 'success',
          });
          fetchRows(self, showExternalContents, showRssContents);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  const handleChange = (newValue: number) => {
    setTabValue(newValue);
    tabLocalStorage.setLocalStorageValue(newValue);
    switch (newValue) {
      case 0:
        setFilters(ApprovementStatus.Draft);
        break;
      case 1:
        setFilters(ApprovementStatus.SentToApproval);
        break;
      case 2:
        setFilters(ApprovementStatus.ApprovedSecondLevel);
        break;
      case 3:
        setFilters(ApprovementStatus.Rejected);
        break;
      case 4:
        setFilters(ApprovementStatus.DeletedSecondLevel);
        break;
    }
  };

  useEffect(() => {
    if (originalRows) {
      switch (filters) {
        case ApprovementStatus.Draft:
          setFilteredRows(originalRows.draftContents ?? []);
          break;
        case ApprovementStatus.SentToApproval:
          setFilteredRows(originalRows.sendToApprovementContents ?? []);
          break;
        case ApprovementStatus.Rejected:
          setFilteredRows(originalRows.rejectedContents ?? []);
          break;
        case ApprovementStatus.ApprovedSecondLevel:
          setFilteredRows(originalRows.approvedContents ?? []);
          break;
        case ApprovementStatus.DeletedSecondLevel:
          setFilteredRows(originalRows.deletedContents ?? []);
          break;
      }
    }
  }, [filters, originalRows]);

  useEffect(() => {
    let abort = new AbortController();
    fetchRows(self, showExternalContents, showRssContents, abort.signal);
    return () => abort.abort();
  }, [selectedInterval, self, showExternalContents, showRssContents]);

  const fetchRows = async (
    self: boolean,
    showExternalContents: boolean,
    showRssContents: boolean,
    signal?: AbortSignal
  ) => {
    dispatch({ type: 'SHOW_QUERY' });
    contentService
      .listAllStatus(
        self,
        showExternalContents,
        showRssContents,
        selectedInterval.startDate,
        selectedInterval.endDate,
        signal
      )
      .then((response) => {
        if (response.canceled) return;

        if (!response.hasError) {
          setOriginalRows({
            draftContents: response.draftContents,
            sendToApprovementContents: response.sendToApprovementContents,
            rejectedContents: response.rejectedContents,
            approvedContents: response.approvedContents,
            deletedContents: response.deletedContents,
          });
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  const handleApprove = (id: any, approve: boolean) => {
    setOpenApproveDialog({ ...openApproveDialog, isSubmitting: true });
    if (
      openApproveDialog.status === ApprovementStatus.ApprovedFirstLevel ||
      openApproveDialog.status === ApprovementStatus.SentToApproval ||
      openApproveDialog.status === ApprovementStatus.Rejected ||
      !approve
    ) {
      contentService
        .updateStatus({
          id: id,
          approverComment: openApproveDialog.comment,
          status: approve
            ? checkPermission(['ApproveContentSecondLevel'])
              ? ApprovementStatus.ApprovedSecondLevel
              : ApprovementStatus.ApprovedFirstLevel
            : ApprovementStatus.Rejected,
        })
        .then((response) => {
          if (response.hasError) {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Sikeres jóváhagyás!', {
              variant: 'success',
            });
            fetchRows(self, showExternalContents, showRssContents);
            setOpenApproveDialog({
              open: false,
              id: '',
              comment: '',
              isSubmitting: false,
              status: ApprovementStatus.Draft,
            });
          }
        });
    } else if (
      openApproveDialog.status === ApprovementStatus.DeletedFirstLevel ||
      openApproveDialog.status === ApprovementStatus.SentToDelete
    ) {
      contentService.delete(id).then((response) => {
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres törlés!', {
            variant: 'success',
          });
          fetchRows(self, showExternalContents, showRssContents);
          setOpenApproveDialog({
            open: false,
            id: '',
            comment: '',
            isSubmitting: false,
            status: ApprovementStatus.Draft,
          });
        }
      });
    }
  };

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (checkPermission(['RecoverContent']) && params.row.isDeleted) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Visszaállítás">
              <AutorenewIcon />
            </Tooltip>
          }
          label={'Visszaállítás'}
          onClick={() => handleRecover(params.row.id)}
        />
      );
    }

    if (
      (checkPermission(['ApproveContentFirstLevel']) &&
        params.row.status === 2) ||
      (checkPermission(['ApproveContentSecondLevel']) &&
        params.row.status === 4) ||
      (checkPermission(['DeleteContentSecondLevel']) &&
        params.row.status === 7) ||
      (checkPermission(['DeleteContentFirstLevel']) && params.row.status === 6)
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Jóváhagyás">
              <CheckIcon />
            </Tooltip>
          }
          label={'Jóváhagyás'}
          onClick={() =>
            setOpenApproveDialog({
              open: true,
              id: params.row.id,
              comment: '',
              isSubmitting: false,
              status: params.row.status,
            })
          }
        />
      );
    }

    if (
      checkPermission(['PublishContent']) &&
      params.row.status === 5 &&
      !params.row.isPublished
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Publikálás">
              <Publish />
            </Tooltip>
          }
          label={'Publikálás'}
          onClick={() =>
            contentService.publish({ id: params.row.id }).then((response) => {
              if (response.hasError) {
                enqueueSnackbar(response.errorMessages.join(','), {
                  variant: 'error',
                });
              } else {
                enqueueSnackbar('Sikeres publikálás!', {
                  variant: 'success',
                });
                fetchRows(self, showExternalContents, showRssContents);
              }
            })
          }
        />
      );
    }

    if (checkPermission(['PinContent']) && params.row.isPublished) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Kitűzés">
              <PushPin color={params.row.isPinned ? 'error' : 'primary'} />
            </Tooltip>
          }
          label={'Kitűzés'}
          onClick={() =>
            contentService.pin({ id: params.row.id }).then((response) => {
              if (response.hasError) {
                enqueueSnackbar(response.errorMessages.join(','), {
                  variant: 'error',
                });
              } else {
                enqueueSnackbar('Sikeres kitűzés!', {
                  variant: 'success',
                });
                fetchRows(self, showExternalContents, showRssContents);
              }
            })
          }
        />
      );
    }

    let readonly = !checkPermission(['ViewContent']) || params.row.isDeleted;
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() => navigate(`/contents/edit/${params.row.id}`)}
      />
    );

    if (
      checkPermission(['DeleteContent']) &&
      (params.row.isDeleted
        ? checkPermission(['DeleteContentPermanently'])
        : true)
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: 'Biztosan törölni szeretné ezt az elemet?',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'imageId',
      headerName: 'Kép?',
      renderCell: (params) => {
        return !!params.value ? (
          <Image color="primary" />
        ) : (
          <HideImage color="disabled" />
        );
      },
      flex: 40,
    },
    {
      field: 'isPinned',
      headerName: '',
      flex: 10,
      renderCell: (params) => (params.value ? <PushPin color="error" /> : null),
    },
    {
      field: 'publicationDateUtc',
      headerName: 'Publikáció dátuma',
      flex: 100,
      valueFormatter: (params) => formatTime(params.value, ''),
    },
    {
      field: 'isPublished',
      headerName: 'Publikálva?',
      flex: 75,
      renderCell: (params) => (params.value ? 'Igen' : 'Nem'),
    },
    { field: 'publisherName', headerName: 'Publikáló', flex: 150 },
    { field: 'shortTitle', headerName: 'Rövid cím', flex: 150 },
    { field: 'version', headerName: 'Verzió', flex: 25 },
    {
      field: 'sendPushNotification',
      headerName: 'Értesítés',
      flex: 75,
      valueFormatter: (params) => (params.value ? 'Igen' : 'Nem'),
    },
    {
      field: 'hasEvent',
      headerName: 'Esemény',
      flex: 100,
      valueFormatter: (params) => (params.value ? 'Igen' : 'Nem'),
    },
    {
      field: 'status',
      headerName: 'Státusz',
      flex: 100,
      valueFormatter: (params) =>
        params.value === ApprovementStatus.Draft
          ? 'Piszkozat'
          : params.value === ApprovementStatus.Rejected
          ? 'Elutasítva'
          : params.value === ApprovementStatus.ApprovedSecondLevel
          ? 'Elfogadva'
          : params.value === ApprovementStatus.SentToApproval ||
            params.value === ApprovementStatus.ApprovedFirstLevel
          ? 'Jóváhagyásra vár'
          : params.value === ApprovementStatus.DeletedFirstLevel ||
            params.value === ApprovementStatus.SentToDelete
          ? 'Törlésre vár'
          : 'Archivált',
    },
    {
      field: 'approverComment',
      headerName: 'Jóváhagyói megjegyzés',
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    contentService.delete(id).then((response) => {
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés!', {
          variant: 'success',
        });
        fetchRows(self, showExternalContents, showRssContents);
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <DatePickerHeader
            selectedInterval={selectedInterval}
            setSelectedInterval={setSelectedInterval}
            tabValue={dateTabValue}
            setTabValue={setDateTabValue}
            netGrossPicker={false}
            localStorageKey={'ContentPage'}
            setNullSelectedInterval
          />
        </Grid>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              pb: 2,
              maxWidth: isSmallScreen ? '280px' : '100%',
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(e, value) => handleChange(value)}
              TabIndicatorProps={{ style: { background: '#1976d2' } }}
              sx={{
                maxWidth: isSmallScreen ? '280px' : '100%',
              }}
              variant="fullWidth"
            >
              <Tab
                value={0}
                label={
                  <Badge
                    badgeContent={originalRows.draftContents?.length ?? 0}
                    color="primary"
                    sx={{ marginRight: '0.5rem' }}
                  >
                    <Box alignContent={'center'} sx={{ marginRight: '0.5rem' }}>
                      Piszkozat{' '}
                    </Box>
                  </Badge>
                }
                sx={{ fontWeight: 'bold' }}
              />
              <Tab
                value={1}
                label={
                  <Badge
                    badgeContent={
                      originalRows.sendToApprovementContents?.length ?? 0
                    }
                    color="primary"
                  >
                    <Box alignContent={'center'} sx={{ marginRight: '0.5rem' }}>
                      Jóváhagyásra vár{' '}
                    </Box>
                  </Badge>
                }
                sx={{ fontWeight: 'bold' }}
              />
              <Tab
                value={2}
                label={
                  <Badge
                    badgeContent={originalRows.approvedContents?.length ?? 0}
                    color="primary"
                  >
                    <Box alignContent={'center'} sx={{ marginRight: '0.5rem' }}>
                      Elfogadva{' '}
                    </Box>
                  </Badge>
                }
                sx={{ fontWeight: 'bold' }}
              />
              <Tab
                value={3}
                label={
                  <Badge
                    badgeContent={originalRows.rejectedContents?.length ?? 0}
                    color="primary"
                  >
                    <Box alignContent={'center'} sx={{ marginRight: '0.5rem' }}>
                      Elutasítva{' '}
                    </Box>
                  </Badge>
                }
                sx={{ fontWeight: 'bold' }}
              />
              <Tab
                value={4}
                label={
                  <Badge
                    badgeContent={originalRows.deletedContents?.length ?? 0}
                    color="primary"
                  >
                    <Box alignContent={'center'} sx={{ marginRight: '0.5rem' }}>
                      Archivált{' '}
                    </Box>
                  </Badge>
                }
                sx={{ fontWeight: 'bold' }}
              />
            </Tabs>
          </Box>
          <Grid item xs={12}>
            <h2>Tartalmak</h2>
          </Grid>
          <Grid container item xs={12} p={2} justifyContent="space-between">
            <Grid item>
              <FormControlLabel
                label="Csak saját cikkek"
                labelPlacement="start"
                control={
                  <Checkbox
                    value={self}
                    checked={self}
                    onChange={(e) => {
                      filtersLocalStorage.setLocalStorageValue({
                        self: e.target.checked,
                        showExternalContents,
                        showRssContents,
                      });
                    }}
                  ></Checkbox>
                }
              />
              <FormControlLabel
                label="RSS cikkek"
                labelPlacement="start"
                control={
                  <Checkbox
                    value={showRssContents}
                    checked={showRssContents}
                    onChange={(e) => {
                      filtersLocalStorage.setLocalStorageValue({
                        self,
                        showExternalContents,
                        showRssContents: e.target.checked,
                      });
                    }}
                  ></Checkbox>
                }
              />
              <FormControlLabel
                label="Külsős cikkek"
                labelPlacement="start"
                control={
                  <Checkbox
                    value={showExternalContents}
                    checked={showExternalContents}
                    onChange={(e) => {
                      filtersLocalStorage.setLocalStorageValue({
                        self,
                        showExternalContents: e.target.checked,
                        showRssContents,
                      });
                    }}
                  ></Checkbox>
                }
              />
            </Grid>
            {checkPermission(['CreateContent']) && (
              <Grid item>
                <Tooltip title="Tartalom létrehozása">
                  <IconButton
                    component={RouterLink}
                    to={`/contents/create`}
                    color="primary"
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <DataList
              rows={filteredRows}
              columns={columns.filter((g) =>
                tabValue !== 3 ? g.field !== 'approverComment' : true
              )}
            />
          </Grid>
          <ConfirmDeleteDialog />
          <ApproveDialog
            openApproveDialog={openApproveDialog}
            setOpenApproveDialog={setOpenApproveDialog}
            handleApprove={handleApprove}
          />
        </Box>
      </Grid>
    </Paper>
  );
};

export default ContentPage;
