import axios from 'axios';
import { postData } from './axiosUtils';
import { ApprovementStatus } from '../pages/contents/ContentPage';

const contentService = {
  list: (status: ApprovementStatus | null) => {
    return axios.get(`/api/content/list?status=${status}`).then((response) => {
      return response.data;
    });
  },
  listAllStatus: (
    self: boolean,
    showExternalContents: boolean,
    showRssContents: boolean,
    startDate: any = null,
    endDate: any = null,
    signal?: AbortSignal
  ) => {
    let url = `/api/content/listAllStatus?self=${self}&showExternalContents=${showExternalContents}&showRssContents=${showRssContents}`;
    if (startDate) url += `&startDate=${startDate?.toISOString()}`;
    if (endDate) {
      url += `&endDate=${endDate?.toISOString()}`;
    }

    return axios.get(url, { signal }).then((response) => {
      return response.data;
    });
  },
  listVersions: (id: any) => {
    return axios.get(`/api/content/listVersions?id=${id}`).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios.get(`/api/content/details/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/content/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/content/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  publish: (entity: any) => {
    return postData({
      url: `/api/content/publish`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  pin: (entity: any) => {
    return postData({
      url: `/api/content/pin`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  updateStatus: (entity: any) => {
    return postData({
      url: `/api/content/updateStatus`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/content/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
  recover: (id: any) => {
    return postData({
      url: `/api/content/recover/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
};

export default contentService;
