import {
  Box,
  Grid,
  IconButton,
  Pagination,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useState } from "react";
import * as XLSX from "xlsx";
import { GetApp } from "@mui/icons-material";
import DataCard from "./DataCard";
const DataList = (props: any) => {
  const isSmallScreen = useMediaQuery("(min-width:800px)");

  const {
    columns,
    rows,
    getRowId = (row: any) => row.id,
    initialState = {},
    processRowUpdate = (row: any) => row,
    exportFields,
    minimal = false,
    excelExportOptions = { fileName: "export", disabled: false },
    ...rest
  } = props;

  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 4;
  const totalCards = rows.length;
  const totalPages = Math.ceil(totalCards / cardsPerPage);
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  function convertObjectsToArrays(objects: any[]) {
    if (!Array.isArray(objects)) {
      throw new Error("Input must be an array of objects");
    }

    if (objects.length === 0) {
      return [];
    }

    const keys = Object.keys(objects[0]).filter(
      (x) => typeof objects[0][x] !== "object"
    );

    const headers = keys.map((key) => key.toUpperCase());
    const rows = objects.map((object) => {
      if (typeof object !== "object") {
        throw new Error("Elements of the input array must be objects");
      }

      return keys.map((key) => {
        const value = object[key];
        if (
          typeof value === "string" &&
          value.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}Z$/)
        ) {
          const date = new Date(value);
          if (isNaN(date.getTime())) {
            return value;
          }
          return date.toLocaleString();
        }

        return value;
      });
    });

    return [headers, ...rows];
  }

  function exportToExcel(data: any) {
    var array = convertObjectsToArrays(data);
    const ws = XLSX.utils.aoa_to_sheet(array);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Adatsor");
    XLSX.writeFile(
      wb,
      excelExportOptions.fileName + "_" + new Date().toLocaleString() + ".xlsx"
    );
  }

  const csvOptions = {
    header: true,
    delimiter: ";",
    fileName: `export_${new Date().toLocaleString()}`,
    columnDelimiter: ";",
    columnSeparator: ";",
    fileExtension: "csv",
    decimalSeparator: ".",
    showTitle: true,
    title: `${new Date().toLocaleString()}`,
    utf8WithBom: true,
  };

  const printOptions = {
    title: `export_${new Date().toLocaleString()}`,
    fileName: `export_${new Date().toLocaleString()}`,
    landscape: true,
    width: "100%",
    height: "100%",
    printBackground: false,
    scale: 1,
    hideToolbar: true,
    useCss: true,
    hideFooter: true,
    fields: exportFields,
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Grid container justifyContent="space-between">
        <Grid item>
          <GridToolbarQuickFilter />
        </Grid>
        {!minimal && (
          <Grid item>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport
              csvOptions={csvOptions}
              printOptions={printOptions}
            />
            {!excelExportOptions.disabled && (
              <Tooltip title="Exportálás excelbe">
                <IconButton color="primary" onClick={() => exportToExcel(rows)}>
                  <GetApp />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        )}
      </Grid>
    </GridToolbarContainer>
  );
  console.log(rows);
  console.log(columns);
  return (
    <Box
      style={{
        height: isSmallScreen ? "50svh" : "",
        width: "100%",
      }}
    >
      {isSmallScreen ? (
        <DataGrid
          density={"compact"}
          experimentalFeatures={{ newEditingApi: true }}
          rows={rows}
          initialState={initialState}
          columns={columns}
          onPageSizeChange={(pageSize: number) => setPageSize(pageSize)}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          editMode="row"
          pagination={true}
          pageSize={pageSize}
          components={{ Toolbar: CustomToolbar }}
          processRowUpdate={processRowUpdate}
          getRowId={getRowId}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          {...rest}
        />
      ) : (
        <Grid>
          <Grid container spacing={1} paddingBottom={2}>
            <IconButton color="primary" onClick={() => exportToExcel(rows)}>
              <GetApp />
            </IconButton>
            {rows.slice(startIndex, endIndex).map((row: any) => (
              <Grid item xs={12} key={getRowId(row)}>
                <DataCard row={row} headers={columns} {...props} />
              </Grid>
            ))}
          </Grid>
          <Grid container justifyContent="center">
            <Grid item>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(_event, value) => handlePageChange(value)}
                color="primary"
                size="small"
                siblingCount={0}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default DataList;
