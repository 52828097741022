import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { Form, Formik } from 'formik';
import 'katex/dist/katex.min.css';
import { useEffect, useRef, useState } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import SunEditorCore from 'suneditor/src/lib/core';
import { number, object, string } from 'yup';
import { InputDiff } from '../../components/InputDiff';
import useCheckPermission from '../../hooks/useCheckPermission';
import { getHtmlContent } from '../../utils/htmContentLoader';
import useImageSelect from '../../hooks/useImageSelect';

const validationSchema = object().shape({
  name: string().required('A cím megadása kötelező'),
  publisherId: number().required('A publikáló megadása kötelező'),
  contentUrl: string().required('A tartalom megadása kötelező'),
});

const PageForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    publishers,
    versions = [],
  } = props;

  const { checkPermission } = useCheckPermission();

  const editor = useRef<SunEditorCore>();
  const [showDifferences, setShowDifferences] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(0);
  const [previousVersion, setPreviousVersion] = useState<any>();

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  const { ImageSelectComponent, uploadImage, uploading, error, uploadedFile } =
    useImageSelect();

  useEffect(() => {
    setSelectedVersion(entity.version);
    setPreviousVersion(versions.find((x: any) => x.version == entity.version));
  }, [entity]);

  useEffect(() => {
    if (previousVersion)
      getHtmlContent(previousVersion.htmlContent).then((content) => {
        setPreviousVersion({
          ...previousVersion,
          htmlContent: content,
        });
      });
  }, [previousVersion?.version]);

  const handleUploaded = (image: any, setFieldValue: any) => {
    setFieldValue('imageId', image.id);
    setFieldValue('image', image);
    setFieldValue('icon', '');
  };

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        let newValues = { ...values };
        if (newValues.publisherId) {
          uploadImage(
            (image: any) =>
              (newValues = {
                ...values,
                image: image,
                imageId: image.id,
                icon: '',
              }),
            'kép',
            newValues.publisherId,
            '-1'
          )?.then(() => {
            onSubmit(newValues, setSubmitting);
          });
        }
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => {
        return (
          <Form>
            <Paper>
              <Grid container justifyContent="left" spacing={2} p={5}>
                <Grid container justifyContent="space-between" item xs={12}>
                  <Grid item>
                    <h2>
                      Oldal {values.id > 0 ? 'szerkesztés' : 'létrehozás'}
                    </h2>
                  </Grid>
                  {previousVersion && (
                    <Grid item container justifyContent="end" xs={8}>
                      <Grid item xs={2}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={showDifferences}
                              onChange={() =>
                                setShowDifferences(!showDifferences)
                              }
                            />
                          }
                          label="Változtatások mutatása"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Autocomplete
                          options={versions.map((x: any) => x.version)}
                          getOptionLabel={(option) => {
                            var version = versions.find(
                              (x: any) => x.version == option
                            );
                            return version?.status == 5
                              ? 'v' + option.toString() + ' (Jóváhagyott)'
                              : 'v' + option.toString();
                          }}
                          value={selectedVersion}
                          onChange={(event, newValue: any) => {
                            setSelectedVersion(newValue);
                            setPreviousVersion(
                              versions.find((x: any) => x.version == newValue)
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Összehasonlítás az alábbi verzióval:"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid xs={12} item pt={5}>
                  <Box color="red">
                    {errors?.map((error: string) => (
                      <li>{error}</li>
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    id="publisherId"
                    disabled={readonly}
                    value={values.publisherId}
                    onChange={(event, value) => {
                      setFieldValue('publisherId', value);
                    }}
                    getOptionLabel={(option) =>
                      publishers?.find((g: any) => g.id === option)?.name ?? ''
                    }
                    options={publishers?.map((g: any) => g.id)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        error={
                          !!touched.publisherId &&
                          !!validationErrors.publisherId
                        }
                        helperText={
                          !!touched.publisherId &&
                          !!validationErrors.publisherId &&
                          (validationErrors.publisherId as string)
                        }
                        label="Publikáló"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={values.name}
                    onChange={handleChange}
                    label="Oldal neve"
                    name="name"
                    required
                    disabled={readonly}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!!touched.name && !!validationErrors.name}
                    helperText={
                      !!touched.name &&
                      !!validationErrors.name &&
                      (validationErrors.name as string)
                    }
                  />
                  {showDifferences && previousVersion && (
                    <InputDiff
                      oldValue={previousVersion.name}
                      newValue={values.name}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <ImageSelectComponent
                    image={values.image}
                    onSelect={(image: any) => {
                      setFieldValue('imageId', image.id);
                      setFieldValue('image', image);
                      setFieldValue('icon', '');
                    }}
                    alt="kép"
                    onUploaded={(image: any) =>
                      handleUploaded(image, setFieldValue)
                    }
                    publisherId={values.publisherId}
                    currentFolderId={'-1'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SunEditor
                    getSunEditorInstance={getSunEditorInstance}
                    width="100%"
                    height="500px"
                    setContents={values.contentUrl}
                    placeholder="Please type here..."
                    disable={readonly}
                    defaultValue={values.contentUrl}
                    onChange={(page: string) => {
                      setFieldValue('contentUrl', page);
                    }}
                    onDrop={(event: any, core: any, cleanData: any) => {
                      console.log('onDrop:', event, core, cleanData);
                    }}
                    setOptions={{
                      buttonList: [
                        ['undo', 'redo'],
                        ['font', 'fontSize', 'formatBlock'],
                        [
                          'bold',
                          'underline',
                          'italic',
                          'strike',
                          'subscript',
                          'superscript',
                        ],
                        ['fontColor', 'hiliteColor', 'textStyle'],
                        ['removeFormat'],
                        '/', // line break
                        ['outdent', 'indent'],
                        ['align', 'horizontalRule', 'list', 'table'],
                        ['link', 'image', 'video'],
                        ['fullScreen', 'showBlocks', 'codeView'],
                        ['preview', 'print'],
                        //['save', 'template'],
                        ['math'],
                        //['imageGallery'],
                      ],
                      katex: 'window.katex',
                      templates: [],
                    }}
                  />
                </Grid>

                <Grid container item pt={3} justifyContent="left">
                  {!readonly && (
                    <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          let newValues = { ...values };
                          if (newValues.publisherId) {
                            uploadImage(
                              (image: any) =>
                                (newValues = {
                                  ...values,
                                  image: image,
                                  imageId: image.id,
                                  icon: '',
                                }),
                              'kép',
                              newValues.publisherId,
                              '-1'
                            )?.then(() => {
                              onSubmit(newValues, isSubmitting, false);
                            });
                          }
                        }}
                        fullWidth={isSmallScreen}
                        disabled={
                          isSubmitting ||
                          !(values.publisherId > 0) ||
                          !(values.name.length > 0)
                        }
                      >
                        Mentés
                      </Button>
                    </Grid>
                  )}
                  {!readonly && (
                    <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          let newValues = { ...values };
                          if (newValues.publisherId) {
                            uploadImage(
                              (image: any) =>
                                (newValues = {
                                  ...values,
                                  image: image,
                                  imageId: image.id,
                                  icon: '',
                                }),
                              'kép',
                              newValues.publisherId,
                              '-1'
                            )?.then(() => {
                              onSubmit(newValues, isSubmitting, true);
                            });
                          }
                        }}
                        fullWidth={isSmallScreen}
                        disabled={isSubmitting}
                      >
                        Mentés piszkozatként
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={isSmallScreen ? 12 : 'auto'} p={1}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth={isSmallScreen}
                      onClick={handleCancel}
                    >
                      Mégse
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PageForm;
