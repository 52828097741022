import {
  Configuration,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: '01ecdca5-9452-4ded-aa42-d82afa94738b',
    authority:
      'https://login.microsoftonline.com/a0d9b470-22dc-45af-a12a-fcb32e8e1bac/',
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

export const loginRequest: RedirectRequest = {
    scopes: ['api://01ecdca5-9452-4ded-aa42-d82afa94738b/access_as_user'],
};
