export const formatTime = (
  params: string | { value: string } = "timeSpan",
  format: string
) => {
  // If params is a string, create an object with a value property set to the string value
  const value = typeof params === "string" ? params : params?.value;
  if (value === null || value === undefined || value === "") {
    return "Nincs";
  }

  // Create a Date object from the input value, assuming it is in UTC format
  const localTime = new Date(value);

  // Format the local time as HH:mm
  if (format === "hours") {
    const formattedTime = localTime.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    // Return the formatted time
    return formattedTime;
  } else {
    const formattedTime = localTime.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    const formattedDate = localTime.toLocaleDateString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    // Return the formatted time
    return `${formattedDate}.${formattedTime}`;
  }
};
