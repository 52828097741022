import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import { Paper, IconButton, Grid, Tooltip } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
  HideImage,
  Image,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useConfirmDeleteDialog from '../../hooks/useConfirmDeleteDialog';
import useCheckPermission from '../../hooks/useCheckPermission';
import DataList from '../../components/DataList';
import mapobjectService from './mapObjectService';

export interface MapObject {
  id: number;
  name: string;
  imageId: number;
  groupId: number;
  isInstitution: boolean;
  groupName: string;
}

const MapObjectsPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    mapobjectService
      .list()
      .then((response) => {
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    let readonly = !checkPermission(['ViewMapObject']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() => navigate(`/mapobjects/edit/${params.row.id}`)}
      />
    );

    if (checkPermission(['DeleteMapObject'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: 'Biztosan törölni szeretné ezt az elemet?',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'imageId',
      headerName: 'Kép?',
      renderCell: (params) => {
        return !!params.value ? (
          <Image color="primary" />
        ) : (
          <HideImage color="disabled" />
        );
      },
      flex: 40,
    },
    { field: 'name', headerName: 'Név', flex: 100 },
    { field: 'description', headerName: 'Leírás', flex: 100 },
    { field: 'pageName', headerName: 'Aloldal', flex: 100 },
    { field: 'fullAddress', headerName: 'Helyszín', flex: 100 },
    {
      field: 'categoryName',
      headerName: 'Kategória',
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    mapobjectService.delete(id).then((response) => {
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés!', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Térképelemek</h2>
        </Grid>
        {checkPermission(['CreateMapObject']) && (
          <Grid container item xs={12} p={2} justifyContent="end">
            <Grid item>
              <Tooltip title="Publikáló létrehozása">
                <IconButton
                  component={RouterLink}
                  to={`/mapobjects/create`}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataList rows={rows} columns={columns} />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
    </Paper>
  );
};

export default MapObjectsPage;
