import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { NavLink } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';

import {
  AccountTree,
  AddLocation,
  AddLocationAlt,
  AdminPanelSettings,
  BrowseGallery,
  Category,
  DeviceHub,
  Domain,
  Feed,
  FormatQuote,
  Group,
  Image,
  Interests,
  Label,
  LocationCity,
  MapOutlined,
  MapsHomeWork,
  Navigation,
  Newspaper,
  People,
  Pin,
  Publish,
  RecentActors,
  ReportProblem,
  Settings,
  Subscriptions,
  WebStories,
} from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import ContentEdit from '../pages/contents/ContentEdit';

export interface MenuItem {
  id: string;
  collapsed: boolean;
  icon: JSX.Element;
  requiredPermissions: string[];
  children: {
    id: string;
    to: string;
    icon: JSX.Element;
    useHref: boolean;
    requiredPermissions: string[];
  }[];
}

export const menuItems: MenuItem[] = [
  {
    id: 'Tartalomkezelő',
    icon: <Newspaper color="primary" />,
    collapsed: false,
    requiredPermissions: [
      'ViewContent',
      'ViewPage',
      'ViewMenuItem',
      'ViewTag',
      'ViewFaqCategory',
      'ViewFaq',
      'ViewOfficial',
    ],
    children: [
      {
        id: 'Tartalmak',
        to: '/contents',
        icon: <Newspaper color="primary" />,
        useHref: false,
        requiredPermissions: ['ViewContent'],
      },
      {
        id: 'Galéria',
        to: '/gallery',
        icon: <Image color="primary" />,
        useHref: false,
        requiredPermissions: [],
      },
      {
        id: 'Aloldalak',
        to: '/pages',
        icon: <WebStories color="primary" />,
        useHref: false,
        requiredPermissions: ['ViewPage'],
      },
      {
        id: 'Menütervező',
        to: '/menuDesigner',
        icon: <AccountTree color="primary" />,
        useHref: false,
        requiredPermissions: ['ViewMenuItem'],
      },
      {
        id: 'Címkék',
        to: '/tags',
        icon: <Label color="primary" />,
        useHref: false,
        requiredPermissions: ['ViewTag'],
      },
      {
        id: 'GYIK kategóriák',
        to: '/faqCategories',
        icon: <Category color="primary" />,
        useHref: false,
        requiredPermissions: ['ViewFaqCategory'],
      },
      {
        id: 'GYIK',
        to: '/faqs',
        icon: <FormatQuote color="primary" />,
        useHref: false,
        requiredPermissions: ['ViewFaq'],
      },
      {
        id: 'Képviselők',
        to: '/officials',
        icon: <People color="primary" />,
        useHref: false,
        requiredPermissions: ['ViewOfficial'],
      },
      {
        id: 'RSS Beállítások',
        to: '/rssSettings',
        icon: <Settings color="primary" />,
        useHref: false,
        requiredPermissions: ['ViewRssSettings'],
      },
    ],
  },
  {
    id: 'Publikálók',
    icon: <Publish color="primary" />,
    collapsed: false,
    requiredPermissions: ['ViewPublisherType', 'ViewPublisher'],
    children: [
      {
        id: 'Publikáló kategóriák',
        to: '/publisherTypes',
        icon: <Category color="primary" />,
        useHref: false,
        requiredPermissions: ['ViewPublisherType'],
      },
      {
        id: 'Publikálók',
        to: '/publishers',
        icon: <Publish color="primary" />,
        useHref: false,
        requiredPermissions: ['ViewPublisher'],
      },
    ],
  },
  {
    id: 'Térkép, helyszínek',
    collapsed: false,
    icon: <MapOutlined color="primary" />,
    requiredPermissions: [
      'ViewMapObjectCategory',
      'ViewMapObject',
      'ViewLocation',
    ],
    children: [
      {
        id: 'Térképelem kategóriák',
        to: '/mapObjectCategories',
        icon: <Interests color="primary" />,
        useHref: false,
        requiredPermissions: ['ViewMapObjectCategory'],
      },
      {
        id: 'Térképelemek',
        to: '/mapObjects',
        icon: <MapOutlined color="primary" />,
        useHref: false,
        requiredPermissions: ['ViewMapObject'],
      },
      {
        id: 'Helyszínek',
        to: '/locations',
        icon: <AddLocationAlt color="primary" />,
        useHref: false,
        requiredPermissions: ['ViewLocation'],
      },
    ],
  },
  {
    id: 'Bejelentések',
    icon: <ReportProblem color="primary" />,
    collapsed: false,
    requiredPermissions: ['ViewReportCategory', 'ViewReport'],
    children: [
      {
        id: 'Bejelentés kategóriák',
        to: '/reportCategories',
        icon: <ReportProblem color="primary" />,
        useHref: false,
        requiredPermissions: ['ViewReportCategory'],
      },
      {
        id: 'Bejelentések',
        to: '/reports',
        icon: <ReportProblem color="primary" />,
        useHref: false,
        requiredPermissions: ['ViewReport'],
      },
    ],
  },
  {
    id: 'Alkalmazás adminisztráció',
    icon: <AdminPanelSettings color="primary" />,
    collapsed: false,
    requiredPermissions: ['Administrator'],
    children: [
      {
        id: 'Email sablonok',
        to: '/erp/emailTemplates',
        icon: <EmailIcon color="primary" />,
        useHref: false,
        requiredPermissions: ['EmailTemplateView'],
      },
      {
        id: 'Felhasználók',
        to: '/authority/users',
        icon: <People color="primary" />,
        useHref: false,
        requiredPermissions: ['ManageAuthorization'],
      },
      {
        id: 'Szerepkörök',
        to: '/authority/roles',
        icon: <RecentActors color="primary" />,
        useHref: false,
        requiredPermissions: ['ManageAuthorization'],
      },
      {
        id: 'Felhasználói csoportok',
        to: '/authority/userGroups',
        icon: <Group color="primary" />,
        useHref: false,
        requiredPermissions: ['ManageAuthorization'],
      },
      {
        id: 'Rendszerbeállítások',
        to: '/systemConfigurations',
        icon: <Settings color="primary" />,
        useHref: false,
        requiredPermissions: ['ManageSystemConfigurations'],
      },
      {
        id: 'Applikáció témák',
        to: '/mobileApplicationTheme',
        icon: <ColorLensIcon color="primary" />,
        useHref: false,
        requiredPermissions: ['ViewMobileApplicationTheme'],
      },
    ],
  },
];
